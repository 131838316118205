import React, { useEffect, useState } from 'react';
import { Button, Snackbar, Avatar } from '@mui/material';
import styles from './trending-messeges.module.scss';
import { AlertService } from '../../../../common/services/alert/alert.service';
import {
  IUserData,
  IUserBadgesData,
  ISubscriptionData,
  IConnectionData,
} from '../../../../common/services/alert/alert.interface';
import { QCNewLogo } from '../../../../assets/svg';
import { countryCodes } from '../../../contact-us/container/constants.js';
import { getSubscriptionType } from '../../../utils/utils.common';

const alertServices = new AlertService();

const TrendingMesseges: React.FC = () => {
  const [showUserAlert, setShowUserAlert] = useState(false);
  const [showUserBadgeAlert, setShowUserBadgeAlert] = useState(false);
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(false);
  const [showConnectionAlert, setShowConnectionAlert] = useState(false);
  const [userData, setUserData] = useState<IUserData>();
  const [userBadgesData, setUserBadgesData] = useState<IUserBadgesData>();
  const [subscriptionData, setSubscriptionsData] =
    useState<ISubscriptionData>();
  const [connectionData, setConnectionData] = useState<IConnectionData>();
  const [isPrevDaysData, setIsPrevDaysData] = useState(false);

  const getUserApi = async (duration: string) => {
    try {
      const response = await alertServices.getUserAlert(duration);
      setUserData(response);
      if (response !== null) {
        setShowUserAlert(true);
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getBadgeApi = async (duration: string) => {
    try {
      const response = await alertServices.getUserBadgesAlert(duration);
      setUserBadgesData(response);
      if (response !== null) {
        setShowUserBadgeAlert(true);
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getSubscriptionApi = async (duration: string) => {
    try {
      const response = await alertServices.getSubscriptionAlert(duration);
      setSubscriptionsData(response);
      if (response !== null) {
        setShowSubscriptionAlert(true);
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getConnectionApi = async (duration: string) => {
    try {
      const response = await alertServices.getConnectionAlert(duration);
      setConnectionData(response);
      if (response !== null) {
        setShowConnectionAlert(true);
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    let fetchUserPrevDayData = false;
    let fetchUserBadgesPrevDayData = false;
    let fetchSubscriptionPrevDayData = false;
    let fetchConnectionPrevDayData = false;
    let userIntervalId: NodeJS.Timeout;
    let badgeIntervalId: NodeJS.Timeout;
    let subscriptionIntervalId: NodeJS.Timeout;
    let connectionIntervalId: NodeJS.Timeout;

    const setUserIntervalData = async () => {
      try {
        const response = await getUserApi('1m');

        if (response === null && !fetchUserPrevDayData) {
          fetchUserPrevDayData = true;
          getUserApi('3d');
          setIsPrevDaysData(true);
        } else {
          setIsPrevDaysData(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const setUserBadgesIntervalData = async () => {
      try {
        const response = await getBadgeApi('1m');

        if (response === null && !fetchUserBadgesPrevDayData) {
          fetchUserBadgesPrevDayData = true;
          getBadgeApi('3d');
        }
      } catch (error) {
        console.error(error);
      }
    };

    const setSubscriptionIntervalData = async () => {
      try {
        const response = await getSubscriptionApi('1m');

        if (response === null && !fetchSubscriptionPrevDayData) {
          fetchSubscriptionPrevDayData = true;
          getSubscriptionApi('3d');
        }
      } catch (error) {
        console.error(error);
      }
    };

    const setConnectionIntervalData = async () => {
      try {
        const response = await getConnectionApi('1m');

        if (response === null && !fetchConnectionPrevDayData) {
          fetchConnectionPrevDayData = true;
          getConnectionApi('3d');
        }
      } catch (error) {
        console.error(error);
      }
    };

    const timeoutId = setTimeout(() => {
      setUserIntervalData();
      setUserBadgesIntervalData();
      setSubscriptionIntervalData();
      setConnectionIntervalData();

      userIntervalId = setInterval(() => {
        setUserIntervalData();
      }, 1000 * 60 * 1);

      badgeIntervalId = setInterval(() => {
        setUserBadgesIntervalData();
      }, 1000 * 60 * 1);

      subscriptionIntervalId = setInterval(() => {
        setSubscriptionIntervalData();
      }, 1000 * 60 * 1);

      connectionIntervalId = setInterval(() => {
        setConnectionIntervalData();
      }, 1000 * 60 * 1);
    }, 3000);

    return () => {
      clearInterval(userIntervalId);
      clearInterval(badgeIntervalId);
      clearInterval(subscriptionIntervalId);
      clearInterval(connectionIntervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleCloseUserAlert = () => {
    setShowUserAlert(false);
  };

  const handleCloseUserBadgesAlert = () => {
    setShowUserBadgeAlert(false);
  };

  const handleCloseSubscriptionAlert = () => {
    setShowSubscriptionAlert(false);
  };

  const handleCloseConnectionAlert = () => {
    setShowConnectionAlert(false);
  };

  const userMessage = (
    <div className={styles.wrapper}>
      <div className={styles.profile}>
        <Avatar src={userData?.profileImageURL || QCNewLogo} />
      </div>
      {userData?.displayName} from{' '}
      {`${userData?.cityName}, ${
        countryCodes.find(
          country => country.code === userData?.countryCode.toLowerCase(),
        )?.name
      }`}{' '}
      {isPrevDaysData ? 'recently' : 'just'} signed up on {userData?.platform}
      <Button color="secondary" size="small" className={styles.btnTrending}>
        Trending
      </Button>
    </div>
  );

  const userBadgesMessage = (
    <div className={styles.wrapper}>
      <div className={styles.profile}>
        <Avatar src={userBadgesData?.profileImageURL || QCNewLogo} />
      </div>
      {userBadgesData?.displayName} from{' '}
      {`${userBadgesData?.cityName}, ${
        countryCodes.find(
          country => country.code === userBadgesData?.countryCode.toLowerCase(),
        )?.name
      }`}{' '}
      just earned {userBadgesData?.badgeName} on {userData?.platform}
      <Button color="secondary" size="small" className={styles.btnTrending}>
        Trending
      </Button>
    </div>
  );

  const subscriptionMessage = (
    <div className={styles.wrapper}>
      <div className={styles.profile}>
        <Avatar src={subscriptionData?.profileImageURL || QCNewLogo} />
      </div>
      {subscriptionData?.displayName} from{' '}
      {`${subscriptionData?.cityName}, ${
        countryCodes.find(
          country =>
            country.code === subscriptionData?.countryCode.toLowerCase(),
        )?.name
      }`}{' '}
      just subscribed to{' '}
      {getSubscriptionType(subscriptionData?.subscriptionType || '')} on{' '}
      {subscriptionData?.platformType}
      <Button color="secondary" size="small" className={styles.btnTrending}>
        Trending
      </Button>
    </div>
  );

  const connectionMessage = (
    <div className={styles.wrapper}>
      <div className={styles.profile}>
        <Avatar src={connectionData?.profileImageURL || QCNewLogo} />
      </div>
      {connectionData?.displayName} from{' '}
      {`${connectionData?.cityName}, ${
        countryCodes.find(
          country => country.code === userBadgesData?.countryCode.toLowerCase(),
        )?.name
      }`}{' '}
      just referred their {connectionData?.referralRank} referral who
      subscribed!
      <Button color="secondary" size="small" className={styles.btnTrending}>
        Trending
      </Button>
    </div>
  );

  return (
    <>
      {userData !== null && !userData?.displayName?.includes('test') && (
        <Snackbar
          open={showUserAlert}
          onClose={handleCloseUserAlert}
          message={userMessage}
          autoHideDuration={30000}
          ContentProps={{
            classes: {
              root: styles.container,
              action: styles.action,
            },
          }}
        />
      )}

      {userBadgesData !== null &&
        !userBadgesData?.displayName.includes('test') && (
          <Snackbar
            open={showUserBadgeAlert}
            onClose={handleCloseUserBadgesAlert}
            message={userBadgesMessage}
            autoHideDuration={30000}
            ContentProps={{
              classes: {
                root: styles.container,
                action: styles.action,
              },
            }}
          />
        )}

      {subscriptionData !== null &&
        !subscriptionData?.displayName.includes('test') && (
          <Snackbar
            open={showSubscriptionAlert}
            onClose={handleCloseSubscriptionAlert}
            message={subscriptionMessage}
            autoHideDuration={30000}
            ContentProps={{
              classes: {
                root: styles.container,
                action: styles.action,
              },
            }}
          />
        )}

      {connectionData !== null &&
        !connectionData?.displayName.includes('test') && (
          <Snackbar
            open={showConnectionAlert}
            onClose={handleCloseConnectionAlert}
            message={connectionMessage}
            autoHideDuration={30000}
            ContentProps={{
              classes: {
                root: styles.container,
                action: styles.action,
              },
            }}
          />
        )}
    </>
  );
};

export default TrendingMesseges;
