/* eslint-disable jsx-a11y/media-has-caption */
import React, { lazy, useState } from 'react';
import { Modal } from '@mui/material';
import { useLocation, useRouteMatch } from 'react-router';
import styles from './welcome.module.scss';
import {
  FaceBookIcon,
  InstagramIcon,
  SnapchatIcon,
  TikTokIcon,
  TwitterIcon,
  WelcomeVideo,
  FastSlowIcon,
  BitmojiIcon,
  Pic2ArtIcon,
  PreEnroll,
  ProtectPromoteIcon,
  Bonus,
  SocialShareIcon,
  TrimIcon,
  VideoPlaceholder,
  WelcomeModal,
  IconMute,
  IconUnmute,
  QuickCamCoins
} from '../../../../assets/images';
import RegisterBlock from './register-block';
import useWindowDimensions from '../../../../common/hooks/useWindowDimensions';
import { UserService } from '../../../../common/services/user/user.service';
import ReferralModal from './referral-modal.component';

const data = [
  {
    image: FastSlowIcon,
    title: 'Real-time Special Effects',
    description: '1-touch fast/slow motion editing while recording',
  },
  {
    image: Pic2ArtIcon,
    title: 'Pic2Art(iPhone only)',
    description: 'Convert your pictures to beautiful art.',
  },
  {
    image: TrimIcon,
    title: 'Video Editor',
    description: 'Quickly trim, cut, crop and share.',
  },
  {
    image: BitmojiIcon,
    title: 'Bitmojis',
    description: 'Add your Snapchat Bitmojis directly to your videos.',
  },
  {
    image: SocialShareIcon,
    title: 'Social Sharing',
    description: (
      <div>
        <div className={styles.featureDescription}>
          QuickShare<sup>TM</sup> on
        </div>
        <div className={styles.iconsRow}>
          <img src={TikTokIcon} alt="TikTok" />
          <img src={SnapchatIcon} alt="Snapchat" />
          <img src={InstagramIcon} alt="Instagram" />
          <img src={FaceBookIcon} alt="Facebook" />
          <img src={TwitterIcon} alt="Twitter" />
        </div>
      </div>
    ),
  },
  {
    image: ProtectPromoteIcon,
    title: 'Invite Wizard',
    description:
      'Use Text Inviter, Email Inviter, QR Code Inviter and DM Inviter',
  },
  {
    image: Bonus,
    title: 'Make Monthly Money From Home',
    description:
      'Earn QuickStart Bonus and from Multi-Tier Affiliate Pay Plan and 5 Generation Infinity Bonus',
  },
  {
    image: PreEnroll,
    title: 'Register For Free',
    description: (
      <p style={{ margin: 0, padding: 0 }}>No Credit Card Required</p>
    ),
  },
];

const videos = [
  'https://quickcam-static-videos.s3.us-east-2.amazonaws.com/welcome.mov',
  'https://quickcam-static-videos.s3.us-east-2.amazonaws.com/pictoart.mov',
  'https://quickcam-static-videos.s3.us-east-2.amazonaws.com/videoeditor.mov',
  'https://quickcam-static-videos.s3.us-east-2.amazonaws.com/bitmoji.mov',
  'https://quickcam-static-videos.s3.us-east-2.amazonaws.com/share.mov',
  'https://quickcam-static-videos.s3.us-east-2.amazonaws.com/invite-wizard.mov',
  'https://quickcam-static-videos.s3.us-east-2.amazonaws.com/infinity-bonus.mov',
  'https://quickcam-static-videos.s3.us-east-2.amazonaws.com/launch.mov',
];

const userServices = new UserService();

const Welcome = (props: {
  isLoggedIn: boolean;
  isLoadingReferrer: boolean;
  referredBy: any;
  getReferrerUser: any;
}) => {
  const [focusID, setFocusID] = useState<number>() as any;
  const [videoSrc, setVideoSrc] = useState(null) as any;
  const [muted, setMuted] = useState(false) as any;
  const [isTimerStarter, setIsTimerStarted] = useState(false);
  const { width } = useWindowDimensions();
  const { isLoggedIn, isLoadingReferrer, referredBy, getReferrerUser } = props;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
  const location = useLocation();
  const match = useRouteMatch();
  const { search } = location;

  const handleActions = (idx: number, isKeepIntervalGoingOn?: boolean) => {
    setFocusID(idx);
    setVideoSrc(videos[idx % videos.length]);
  };

  const handleShadowOnFocus = (event: { currentTarget: any }) => {
    const element = event.currentTarget;
    element?.classList.add(styles.shadow);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    handleActions(0);
  };

  const handleReferralClose = () => {
    handleActions(0);
  };

  return (
    <>
      {!isLoadingReferrer && !isTimerStarter && (
        <>
          {!referredBy?._id && !isLoadingReferrer ? (
            <Modal
              open={isModalVisible}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div onClick={closeModal}>
                <img
                  className={styles.modalImage}
                  src={WelcomeModal}
                  alt="WelcomeModal"
                />
              </div>
            </Modal>
          ) : (
            <ReferralModal
              referredBy={referredBy}
              onClose={handleReferralClose}
              getReferrerUser={(channelId: string, startNow: boolean) => {
                getReferrerUser(channelId, startNow);
                setIsTimerStarted(true);
              }}
            />
          )}
        </>
      )}

      <section className={styles.welcomeSection} id="home">
        <div className={styles.welcomeHeader}>
          <div className={styles.welcomeHeaderContainer}>
            <div className={styles.welcomeText}>Welcome to QuickCam!</div>
            {width < 768 && (
              <div className={styles.welcomeDescription}>
                New Patented Camera App You Can Make Money With!
              </div>
            )}
          </div>
          {width > 768 && (
            <div className={styles.welcomeDescription}>
              New Patented Camera App You Can Make Money With!
            </div>
          )}
        </div>
      </section>
      {/* <RegisterBlock isLoggedIn={isLoggedIn} /> */}
      <section>
        <div className={styles.mWebWelcomeContainer}>
          <div className={styles.webWelcomeContent}>
            <div className={[styles.options, styles.left].join(' ')}>
              <div className={[styles.option, styles.first].join(' ')}>
                <div>
                  <div>
                    <div className={styles.featureTitle}>
                      Real-time Special Effects
                    </div>
                    <div className={styles.featureDescription}>
                      1-touch fast/slow motion editing while recording
                    </div>
                  </div>
                  <button
                    type="button"
                    id="btnIcon"
                    className={
                      focusID === 0
                        ? [styles.optionBtn, styles.shadow].join(' ')
                        : [styles.optionBtn, styles.noshadow].join(' ')
                    }
                    onClick={() => handleActions(0)}
                    onFocus={e => handleShadowOnFocus(e)}
                  >
                    <img src={FastSlowIcon} alt="Pic2Art" />
                  </button>
                </div>
              </div>
              <div className={[styles.option, styles.second].join(' ')}>
                <div>
                  <div>
                    <div className={styles.featureTitle}>Video Editor</div>
                    <div className={styles.featureDescription}>
                      Quickly trim, cut, crop and share.
                    </div>
                  </div>
                  <button
                    type="button"
                    id="btnIcon"
                    className={
                      focusID === 2
                        ? [styles.optionBtn, styles.shadow].join(' ')
                        : [styles.optionBtn, styles.noshadow].join(' ')
                    }
                    onClick={() => handleActions(2)}
                    onFocus={e => handleShadowOnFocus(e)}
                  >
                    <img src={TrimIcon} alt="Pic2Art" />
                  </button>
                </div>
              </div>
              <div className={[styles.option, styles.second].join(' ')}>
                <div>
                  <div>
                    <div className={styles.featureTitle}>Bitmojis</div>
                    <div className={styles.featureDescription}>
                      Add your Snapchat Bitmojis directly to your videos.
                    </div>
                  </div>
                  <button
                    type="button"
                    id="btnIcon"
                    className={
                      focusID === 3
                        ? [styles.optionBtn, styles.shadow].join(' ')
                        : [styles.optionBtn, styles.noshadow].join(' ')
                    }
                    onClick={() => handleActions(3)}
                    onFocus={e => handleShadowOnFocus(e)}
                  >
                    <img src={BitmojiIcon} alt="Pic2Art" />
                  </button>
                </div>
              </div>
              <div className={[styles.option, styles.third].join(' ')}>
                <div>
                  <div>
                    <div className={styles.featureTitle}>Referral Dashboard</div>
                    <div className={styles.featureDescription}>
                      Track your referrals and request payment.
                    </div>
                  </div>
                  <button
                    type="button"
                    id="btnIcon"
                    className={
                      focusID === 3
                        ? [styles.optionBtn, styles.shadow].join(' ')
                        : [styles.optionBtn, styles.noshadow].join(' ')
                    }
                    onClick={() => handleActions(3)}
                    onFocus={e => handleShadowOnFocus(e)}
                  >
                    <img src={ProtectPromoteIcon} alt="Pic2Art" />
                  </button>
                </div>
              </div>
            </div>
            {width > 1024 && (
              <div className={styles.outerCircle}>
                <div className={styles.middleCircle}>
                  <div className={styles.innerCircle}>
                    {/* <img src={WelcomeVideo} alt="Pic2Art" /> */}
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: 1,
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p>Loading...</p>
                      </div>
                      {videoSrc ? (
                        <video
                          autoPlay
                          muted={muted}
                          playsInline
                          controls={false}
                          style={{ position: 'absolute', zIndex: 10 }}
                          key={videoSrc}
                          onEnded={() => {
                            handleActions((focusID + 1) % data.length, true);
                          }}
                        >
                          <source src={videoSrc} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={VideoPlaceholder}
                          alt="video"
                          style={{ height: '100%', width: '100%', zIndex: 1 }}
                        />
                      )}
                      {/* eslint-disable */}
                      <img
                        src={muted ? IconMute : IconUnmute}
                        className={styles.videoMuteImage}
                        onClick={() => setMuted(!muted)}
                        alt="mute"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={[styles.options, styles.right].join(' ')}>
              <div className={[styles.option, styles.first].join(' ')}>
                <div>
                  <button
                    type="button"
                    id="btnIcon"
                    className={
                      focusID === 4
                        ? [styles.optionBtn, styles.shadow].join(' ')
                        : [styles.optionBtn, styles.noshadow].join(' ')
                    }
                    onClick={() => handleActions(4)}
                    onFocus={e => handleShadowOnFocus(e)}
                  >
                    <img src={SocialShareIcon} alt="Pic2Art" />
                  </button>
                  <div>
                    <div className={styles.featureTitle}>Social Sharing</div>
                    <div className={styles.featureDescription}>
                      <p>
                        QuickShare<sup>TM</sup> on
                      </p>
                    </div>
                    <div className={styles.iconsRow}>
                      <img src={TikTokIcon} alt="TikTok" />
                      <img src={SnapchatIcon} alt="Snapchat" />
                      <img src={InstagramIcon} alt="Instagram" />
                      <img src={FaceBookIcon} alt="Facebook" />
                      <img src={TwitterIcon} alt="Twitter" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={[styles.option, styles.second].join(' ')}>
                <div>
                  <button
                    type="button"
                    id="btnIcon"
                    className={
                      focusID === 5
                        ? [styles.optionBtn, styles.shadow].join(' ')
                        : [styles.optionBtn, styles.noshadow].join(' ')
                    }
                    onClick={() => handleActions(5)}
                    onFocus={e => handleShadowOnFocus(e)}
                  >
                    <img src={QuickCamCoins} alt="Pic2Art" />
                  </button>
                  <div>
                    <div className={styles.featureTitle}>
                      QuickCam Coins Cryptocurrency
                    </div>
                    <div className={styles.featureDescription}>
                      3 ways to Earn QuickCam Coins!
                    </div>
                  </div>
                </div>
              </div>
              <div className={[styles.option, styles.second].join(' ')}>
                <div>
                  <button
                    type="button"
                    id="btnIcon"
                    className={
                      focusID === 6
                        ? [styles.optionBtn, styles.shadow].join(' ')
                        : [styles.optionBtn, styles.noshadow].join(' ')
                    }
                    onClick={() => handleActions(6)}
                    onFocus={e => handleShadowOnFocus(e)}
                  >
                    <img src={Bonus} alt="Pic2Art" />
                  </button>
                  <div>
                    <div className={styles.featureTitle}>
                      Make Monthly Money From Home
                    </div>
                    <div className={styles.featureDescription}>
                      Earn Passive Residual Income from the 2-Tier Referral
                      System.
                    </div>
                  </div>
                </div>
              </div>
              <div className={[styles.option, styles.third].join(' ')}>
                <div>
                  <button
                    type="button"
                    id="btnIcon"
                    className={
                      focusID === 7
                        ? [styles.optionBtn, styles.shadow].join(' ')
                        : [styles.optionBtn, styles.noshadow].join(' ')
                    }
                    onClick={() => handleActions(7)}
                    onFocus={e => handleShadowOnFocus(e)}
                  >
                    <img src={PreEnroll} alt="Pic2Art" />
                  </button>
                  <div>
                    <div className={styles.featureTitle}>Register For Free</div>
                    <div className={styles.featureDescription}>
                      No Credit Card Required
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.mwebWelcomeContent}>
            <div
              className={[
                styles.verticalButtons,
                styles.verticalLeftButtons,
              ].join(' ')}
            >
              <button
                onClick={() => handleActions(0)}
                onFocus={e => handleShadowOnFocus(e)}
                type="button"
                id="btnIcon"
                className={
                  focusID === 0
                    ? [styles.shadow].join(' ')
                    : [styles.noshadow].join(' ')
                }
              >
                <img src={FastSlowIcon} alt="Pic2Art" />
              </button>
              <button
                onClick={() => handleActions(1)}
                onFocus={e => handleShadowOnFocus(e)}
                type="button"
                id="btnIcon"
                className={
                  focusID === 1
                    ? [styles.shadow].join(' ')
                    : [styles.noshadow].join(' ')
                }
              >
                <img src={Pic2ArtIcon} alt="Pic2Art" />
              </button>
              <button
                onClick={() => handleActions(2)}
                onFocus={e => handleShadowOnFocus(e)}
                type="button"
                id="btnIcon"
                className={
                  focusID === 2
                    ? [styles.shadow].join(' ')
                    : [styles.noshadow].join(' ')
                }
              >
                <img src={TrimIcon} alt="Pic2Art" />
              </button>
              <button
                onClick={() => handleActions(3)}
                onFocus={e => handleShadowOnFocus(e)}
                type="button"
                id="btnIcon"
                className={
                  focusID === 3
                    ? [styles.shadow].join(' ')
                    : [styles.noshadow].join(' ')
                }
              >
                <img src={BitmojiIcon} alt="Pic2Art" />
              </button>
            </div>
            <div className={styles.mwebVideoContainerBorder}>
              <div className={styles.featureContainer}>
                <div className={styles.featureTitle}>
                  {data[focusID]?.title}
                </div>
                <div className={styles.featureDescription}>
                  {data[focusID]?.description}
                </div>
              </div>
              {width <= 1024 && (
                <div className={styles.mwebVideoContainer}>
                  <img src={WelcomeVideo} alt="Pic2Art" />
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <p>Loading</p>
                    </div>
                    {videoSrc ? (
                      <video
                        autoPlay
                        muted={muted}
                        playsInline
                        controls={false}
                        style={{ position: 'absolute', zIndex: 2 }}
                        key={videoSrc}
                        onEnded={() => {
                          handleActions((focusID + 1) % data.length, true);
                        }}
                      >
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={VideoPlaceholder}
                        alt="video"
                        style={{ height: '100%', width: '100%', zIndex: 1 }}
                      />
                    )}
                    <img
                      src={muted ? IconMute : IconUnmute}
                      className={styles.videoMuteImage}
                      onClick={() => setMuted(!muted)}
                      alt="mute"
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className={[
                styles.verticalButtons,
                styles.verticalRightButtons,
              ].join(' ')}
            >
              <button
                onClick={() => handleActions(4)}
                onFocus={e => handleShadowOnFocus(e)}
                type="button"
                id="btnIcon"
                className={
                  focusID === 4
                    ? [styles.shadow].join(' ')
                    : [styles.noshadow].join(' ')
                }
              >
                <img src={SocialShareIcon} alt="Pic2Art" />
              </button>
              <button
                onClick={() => handleActions(5)}
                onFocus={e => handleShadowOnFocus(e)}
                type="button"
                id="btnIcon"
                className={
                  focusID === 5
                    ? [styles.shadow].join(' ')
                    : [styles.noshadow].join(' ')
                }
              >
                <img src={ProtectPromoteIcon} alt="Pic2Art" />
              </button>
              <button
                onClick={() => handleActions(6)}
                onFocus={e => handleShadowOnFocus(e)}
                type="button"
                id="btnIcon"
                className={
                  focusID === 6
                    ? [styles.shadow].join(' ')
                    : [styles.noshadow].join(' ')
                }
              >
                <img src={Bonus} alt="Pic2Art" />
              </button>
              <button
                onClick={() => handleActions(7)}
                onFocus={e => handleShadowOnFocus(e)}
                type="button"
                id="btnIcon"
                className={
                  focusID === 7
                    ? [styles.shadow].join(' ')
                    : [styles.noshadow].join(' ')
                }
              >
                <img src={PreEnroll} alt="Pic2Art" />
              </button>
            </div>
          </div>
          {/* <div style={{ textAlign: 'center', padding: '0 0 1rem 0' }}>
            <div className={styles.featureDescription}>
              QuickCam provides you with unique multi-patented real-time fast &
              slow motion recording feature to make recording, editing & sharing
              videos quick and easy!
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Welcome;
